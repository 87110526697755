import http from "../http-common";

class BookService {
  login(data) {
    return http.post("/auth/login", data);
  }

  create(data, token) {
    return http.post("/book", data, {
      headers:{
          'Authorization':'Bearer '+token,
      }
    });
  }

  cancel(id,token) {
    return http.put("/book/"+id, {}, {
      headers:{
          'Authorization':'Bearer '+token,
      }
    });
  }

  dashboard(token, inicio, fim, hoje) {
    return http.post(`/book/dashboard`,{
      inicio,
      fim,
      hoje
    },{
      headers:{
          'Authorization':'Bearer '+token,
      }
    });
  }

  edit(id,data,token) {
    return http.patch("/book/"+id, data, {
      headers:{
          'Authorization':'Bearer '+token,
      }
    });
  }

  getAll(data) {
    return http.get(`/book?date_from=${data}&date_to=${data}&active=1`);
  }

  getAllAdmin(date_from, date_to) {
    return http.get(`/book?date_from=${date_from}&date_to=${date_to}&active=1`);
  }


}

export default new BookService();