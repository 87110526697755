<template>

    <h3 class="text-2xl py-5 text-center">Bem vindo {{ name }}</h3>

    <template v-if="data && data.length">

        <div class="flex w-full gap-3 mb-2">
            <div class="w-6/12 bg-green-400 rounded text-white px-3 py-4 flex flex-col shadow-lg">
                <span class="font-bold">Reservas hoje</span>
                <p class="text-4xl text-end">{{data[0].quantidade_hoje}}</p>
            </div>
            <div class="w-6/12 bg-green-500 rounded text-white px-3 py-4 flex flex-col shadow-lg">
                <span class="font-bold">Reservas semana</span>
                <p class="text-4xl text-end">{{data[0].quantity_ate_fim_semana}}</p>
            </div>
        </div>

        <div class="flex w-full gap-3 mb-2">
            <div class="w-full bg-orange-400 rounded text-white px-3 py-4 flex flex-col shadow-lg">
                <span class="font-bold">Horarios livres na semana</span>
                <p class="text-4xl text-end">{{data[0].horarios_livres}}/{{data[0].horarios_todos}}</p>
            </div>
        </div>

        <div class="flex w-full gap-3 mb-2">
            <div class="w-6/12 bg-purple-400 rounded text-white px-3 py-4 flex flex-col shadow-lg">
                <span class="font-bold">Reservas confir. hoje</span>
                <p class="text-4xl text-end">{{data[0].confirmados_hoje}}</p>
            </div>
            <div class="w-6/12 bg-purple-500 rounded text-white px-3 py-4 flex flex-col shadow-lg">
                <span class="font-bold">Reservas confir. semana</span>
                <p class="text-4xl text-end">{{data[0].confirmados_semana}}</p>
            </div>
        </div>

        <div class="flex w-full gap-3 mb-2">
            <div class="w-6/12 bg-blue-400 rounded text-white px-3 py-4 flex flex-col shadow-lg">
                <span class="font-bold">Valor R$ Hoje</span>
                <p class="text-4xl text-end">{{(data[0].valor_hoje || 0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}</p>
            </div>
            <div class="w-6/12 bg-blue-500 rounded text-white px-3 py-4 flex flex-col shadow-lg">
                <span class="font-bold">Valor R$ Semana</span>
                <p class="text-4xl text-end">{{(data[0].valor_semana || 0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}</p>
            </div>
        </div>


    </template>

</template>

<script>
    import { ref, onMounted } from 'vue'
    import BookService from "../../../services/book";
    import { useAuthStore } from '../../../stores/auth'
    import Moment from 'moment'

    export default{
        //title: 'Homepage Title',
        props:['name'],

        setup(){

            const data = ref([])
            const authStore = useAuthStore()

            const getData = () =>{
                BookService.dashboard(authStore.token, Moment().startOf('week').format('YYYY-MM-DD'), Moment().endOf('week').format('YYYY-MM-DD'),Moment().format('YYYY-MM-DD'))
                .then(r=>{
                    data.value = r.data.data
                })
                .catch(e=>{
                    if(e.response.status==401){
                        logout()
                    }
                })
            }

            function logout(){
                localStorage.removeItem('auth')
                setTimeout(()=>{
                    window.location.href="/admin"
                },500)
            }

            onMounted(()=>{
                getData()
                console.log(Moment().endOf('week').format('YYYY-MM-DD'))
                console.log(Moment().startOf('week').format('YYYY-MM-DD'))
                console.log(Moment().format('YYYY-MM-DD'))
            })

            return {
                data
            }
        }
    }
</script>