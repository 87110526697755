import http from "../http-common";

class OrderService {


  create(data, token) {
    return http.post("/order", data, {
      headers:{
          'Authorization':'Bearer '+token,
      }
    });
  }


}

export default new OrderService();