<template>
  <router-view/>
</template>

<style>
  .bg-primary{
    background-color: var(--el-color-primary);
  }

  .text-primary{
    color: var(--el-color-primary);
  }

  .bg-primary-select{
    background-color: var(--el-color-primary);
    filter: brightness(85%);
  }
</style>

<script>
  export default{
    created(){
      //document.title = ''      
    }
  }
</script>