import { defineStore } from 'pinia'

export const useAuthStore = defineStore({
  persist: true,
  id: 'auth',
  state: () => ({
    logged: false,
    user:{},
    token:null
  }),
  getters: {
    // isFontLarge: (state) => state.fontSize > 30
  },
  actions: {
    logginSuccess(data) {
      this.logged = true
      this.token = data.access_token
    },
    setUserLogged(data) {
      this.user = data
    },
    // decreaseFontSize(amount) {
    //   this.fontSize -= amount
    // },
    // resetFontSize() {
    //   this.fontSize = 16
    // }
  }
})