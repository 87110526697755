<template>
  <div class="" :class="authStore.logged?'mb-2':'mb-2'">
    
    <!-- <div class="flex">

      <svg @click="toggleDark()" v-if="!isDark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
        <path fill-rule="evenodd" d="M7.455 2.004a.75.75 0 01.26.77 7 7 0 009.958 7.967.75.75 0 011.067.853A8.5 8.5 0 116.647 1.921a.75.75 0 01.808.083z" clip-rule="evenodd" />
      </svg>

      <svg @click="toggleDark()" v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
        <path d="M10 2a.75.75 0 01.75.75v1.5a.75.75 0 01-1.5 0v-1.5A.75.75 0 0110 2zM10 15a.75.75 0 01.75.75v1.5a.75.75 0 01-1.5 0v-1.5A.75.75 0 0110 15zM10 7a3 3 0 100 6 3 3 0 000-6zM15.657 5.404a.75.75 0 10-1.06-1.06l-1.061 1.06a.75.75 0 001.06 1.06l1.06-1.06zM6.464 14.596a.75.75 0 10-1.06-1.06l-1.06 1.06a.75.75 0 001.06 1.06l1.06-1.06zM18 10a.75.75 0 01-.75.75h-1.5a.75.75 0 010-1.5h1.5A.75.75 0 0118 10zM5 10a.75.75 0 01-.75.75h-1.5a.75.75 0 010-1.5h1.5A.75.75 0 015 10zM14.596 15.657a.75.75 0 001.06-1.06l-1.06-1.061a.75.75 0 10-1.06 1.06l1.06 1.06zM5.404 6.464a.75.75 0 001.06-1.06l-1.06-1.06a.75.75 0 10-1.061 1.06l1.06 1.06z" />
      </svg>


    </div> -->
    <div class="text-center text-3xl font-bold flex justify-center">
      <img :src="LogoJPG" style="height:300px;" alt="">
    </div>
    <p class="text-center text-2xl mt-2 mb-3">Agende um horário</p>

    <!-- <div v-if="authStore.logged" class="flex justify-center px-3 items-center gap-2 pt-3 mt-3 border-t ">
    
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
        <path d="M10 8a3 3 0 100-6 3 3 0 000 6zM3.465 14.493a1.23 1.23 0 00.41 1.412A9.957 9.957 0 0010 18c2.31 0 4.438-.784 6.131-2.1.43-.333.604-.903.408-1.41a7.002 7.002 0 00-13.074.003z" />
      </svg> Olá {{authStore.user.name}} -  <el-button link type="primary" >Minhas reservas</el-button>

    </div> -->
  </div>    
</template>

<script setup >
import { useDark, useToggle } from '@vueuse/core'
import { useAuthStore } from '../stores/auth'
import Logo from '../views/public/logo.png'

const isDark = useDark()
const toggleDark = useToggle(isDark) 

const LogoJPG  = Logo
const authStore = useAuthStore()

</script>

<style>

</style>