<template>
    <div>
        <h3 class="text-2xl py-5 text-center">Sessões</h3>
        <div class="slider-demo-block">
            <el-slider v-model="value" range show-stops :max="23" />
        </div>
        
        <div class="flex justify-between py-6 px-2">
            <svg @click="dayInitial=moment()(dayInitial).add({days:-7}).format('YYYY-MM-DD');getEvents();key++;" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer">
                <path stroke-linecap="round" stroke-linejoin="round" d="M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5" />
            </svg>

            <svg @click="dayInitial=moment()(dayInitial).add({days:+7}).format('YYYY-MM-DD');getEvents();" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer">
                <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5" />
            </svg>

        </div>
        <!-- {{ dayInitial }} -->
    </div>
    <div :key="key" v-loading="load" class="flex flex-col gap-2">
        <div v-for="(i2, idx2) in hours" class="flex gap-2 justify-center">
            <div v-for="(i,idx) in days" style="width:35px;height: 35px;min-width:35px;min-height: 35px;" class="bg-gray-200 rounded flex justify-center items-center" :class="idx2==0 || idx==0?'bg-gray-100':'bg-gray-200'">
                
                
                <el-popover
                    placement="bottom"
                    :title="moment()(i).format('DD/MM/YYYY')+' às '+moment()(i2, 'HH:mm:ss').format('HH:mm')"
                    :width="200"
                    trigger="click"
                    content=""
                >
                    <template #reference>
                        <div class="text-xs font-bold" v-if="idx==0">{{ i2?i2.substr(0,2)+'h':'' }}</div>
                        <div v-else class="h-full w-full">
                            <div v-if="selecteds.filter(it=>it.date==i).length && selecteds.filter(it=>it.date==i)[0].hours.filter(it=>it.hour==i2 && it.action=='add').length" 
                                 class="rounded h-full w-full cursor-pointer" :class="listBooks.filter(itb=>itb.start_time_event==i+' '+i2).length?'bg-red-200':'bg-green-200'">

                            </div>
                            <div v-else class="bg-gray-200 rounded h-full w-full cursor-pointer">

                            </div>
                        </div>
                    </template>
                    <div class="flex flex-col gap-1">
                        <div v-if="(selecteds.filter(it=>it.date==i).length && selecteds.filter(it=>it.date==i)[0].hours.filter(it=>it.hour==i2 && it.action=='add').length) && (listBooks.filter(itb=>itb.start_time_event==i+' '+i2).length)"  style="word-break: initial;">
                            <p class="py-2">Reservado para 
                                <br><b>{{listBooks.filter(itb=>itb.start_time_event==i+' '+i2)[0].name_user}}</b>
                            </p>
                            <div 
                                @click="openConfirmDelete(listBooks.filter(itb=>itb.start_time_event==i+' '+i2)[0].id)"
                                class="bg-red-300 text-black px-2 py-1 rounded cursor-pointer">
                                Cancelar reserva
                            </div>
                        </div>
                        <div v-if="!(selecteds.filter(it=>it.date==i).length && selecteds.filter(it=>it.date==i)[0].hours.filter(it=>it.hour==i2 && it.action=='add').length)" 
                            class="bg-gray-300 text-black px-2 py-1 rounded cursor-pointer" 
                            @click="select(i2,i);">
                            Abrir sessão
                        </div>
                        <div v-if="(selecteds.filter(it=>it.date==i).length && selecteds.filter(it=>it.date==i)[0].hours.filter(it=>it.hour==i2 && it.action=='add').length) && !(listBooks.filter(itb=>itb.start_time_event==i+' '+i2).length)" 
                             @click="dialogVisibleReserva = !dialogVisibleReserva;daySelected=i;hourSelected=i2;" class="bg-blue-300 text-black px-2 py-1 rounded cursor-pointer">
                             Reservar para alguém
                        </div>
                        <div v-if="(selecteds.filter(it=>it.date==i).length && selecteds.filter(it=>it.date==i)[0].hours.filter(it=>it.hour==i2 && it.action=='add').length) && !(listBooks.filter(itb=>itb.start_time_event==i+' '+i2).length)" 
                             @click="select(i2,i);" class="bg-gray-300 text-black px-2 py-1 rounded cursor-pointer">
                             Fechar sessão
                        </div>
                    </div>
                </el-popover>
                
                <div v-if="idx2==0" class="flex flex-col justify-center items-center">
                    <div class="text-xs font-bold" v-if="moment()(i).format('ddd')!='Data inválida'">{{ moment()(i).format('DD/MM') }}</div>
                    <div class="text-xs capitalize" style="margin-top:0px;"  v-if="moment()(i).format('ddd')!='Data inválida'">{{ moment()(i).format('ddd') }}</div>
                </div>

            </div>
        </div>
    </div>

    <el-dialog
        v-model="dialogVisibleReserva"
        title="Confirmação"
        width="95%"
        @closed="handleClose()"
    >
        <!-- {{ keyPaymentCall }} -->
        <span v-if="messageError != 'voce esta sem creditos'">Deseja mesmo fazer esta reserva para o dia <b>{{moment()(daySelected).format('DD/MM/YYYY')}}</b> às <b>{{moment()(hourSelected, 'HH:mm:ss').format('HH:mm')}}</b> ? Preencha os dados da pessoa para reservar esse horário.</span>


        <div class="py-5">
        <form action="" class="flex flex-col gap-5">
            
            <div>
            <label for="true">Nome completo</label>
            <input placeholder="Seu nome" v-model="name_user" type="text" class="border w-full px-2 py-3 text-md"/>
            </div>

            <div>
            <label for="true">Telefone</label>
            <input placeholder="(99) 99999-9999" v-mask="'(00) 00000-0000'" type="text" v-model="mobile_phone" class="border w-full px-2 py-3 text-md"/>
            </div>

            <div>
            <label for="true">Data de Nascimento</label>
            <input v-model="date_of_birthday" placeholder="DD/MM/AAAA" v-mask="'00/00/0000'"  type="text" class="border w-full px-2 py-3 text-md"/>
            </div>
            
        </form>
        </div>
        


        <template #footer >
        <span v-if="messageError != 'voce esta sem creditos'" class="dialog-footer flex items-center justify-end gap-2" v-loading="load">
            <el-button @click="dialogVisibleReserva = false">Cancelar</el-button>
            <button 
                @click="createBook()"
                :disabled="!(name_user && mobile_phone && mobile_phone.length==15 && date_of_birthday && date_of_birthday.length==10)"
                class="bg-primary px-4 py-1 text-white rounded hover:opacity-90" 
                :class="!(name_user && mobile_phone && mobile_phone.length==15 && date_of_birthday && date_of_birthday.length==10) && 'opacity-50 hover:opacity-50'"
                >
                Confirmar
            </button>
        </span>
        </template>
    </el-dialog>

    <div class="flex justify-center py-5">
        <button class="bg-primary px-6 py-2 text-white rounded" @click="create()">Salvar</button>
    </div>
</template>

<script>
    import {ref,onMounted, watch} from 'vue'
    import Moment from 'moment'
    import EventService from "../../../services/event";
    import BookService from "../../../services/book";
    import { useAuthStore } from '../../../stores/auth'
    import { ElNotification } from 'element-plus'
    import { ElMessage, ElMessageBox } from 'element-plus'

    export default{
        props:['name'],
        setup(){
            const authStore = useAuthStore()

            const load = ref(false)

            const value = ref(localStorage.getItem('rangeHour')? JSON.parse(localStorage.getItem('rangeHour')) : [8,20] )

            const days = ref([''])
            const hours = ref([''])
            const dayInitial = ref(Moment().format('YYYY-MM-DD'))
            const inicialHours = ref(8)
            const finalHours = ref(14)
            const key = ref(0)
            const daySelected = ref('')
            const hourSelected = ref('')

            const mobile_phone = ref('')
            const date_of_birthday = ref('')
            const name_user = ref('')

            const eventList = ref([])



            const dialogVisibleReserva = ref(false)

            const listBooks = ref([])


            const selecteds = ref([])

            const moment = ()=>{
                return Moment
            }
            
            const create = () =>{
                load.value = true
                let data = {
                    data:selecteds.value
                }
                EventService.create(data, authStore.token)
                .then(r=>{
                    ElNotification({
                        title: 'Sucesso',
                        message: 'As alterações foram realizadas!',
                        type: 'success',
                    })
                })
                .finally(()=>{
                    getEvents()

                })
            }


            const createBook = () =>{
                load.value = true
                //console.log(hourSelected.value, daySelected.value)

                let idEvent = undefined

                if(eventList.value.filter(it=>it.start_time==daySelected.value+' '+hourSelected.value).length){
                    idEvent = eventList.value.filter(it=>it.start_time==daySelected.value+' '+hourSelected.value)[0].id
                }

                //console.log(idEvent)
 
                BookService.create(
                    {
                        id_user:authStore.user.id,
                        id_event: idEvent,
                        name_user:name_user.value,
                        date_of_birthday:date_of_birthday.value.split('/').reverse().join('-'),
                        mobile_phone:mobile_phone.value
                    },
                    authStore.token
                )
                .then(r=>{
                    ElMessage({
                        type: 'success',
                        message: 'Reserva feita com sucesso!',
                    })
                    name_user.value = ''
                    date_of_birthday.value = ''
                    mobile_phone.value = ''
                    dialogVisibleReserva.value = false

                })
                .catch(()=>{
                    ElMessage({
                        type: 'error',
                        message: 'Erro ao tentar reservar',
                    })
                })
                .finally(()=>{
                    getEvents()
                    load.value = false
                })
            }

            const openConfirmDelete = (id) => {
                ElMessageBox.confirm(
                    'Deseja mesmo cancelar esta reserva?',
                    'Cancelar reserva',
                    {
                    confirmButtonText: 'Confirmar',
                    cancelButtonText: 'Cancelar',
                    type: 'warning',
                    }
                )
                .then(() => {
                    BookService.cancel(id, authStore.token)
                    .then(r=>{
                        ElMessage({
                            type: 'success',
                            message: 'Cancelada com sucesso!',
                        })
                    })
                    .catch(()=>{
                        ElMessage({
                            type: 'error',
                            message: 'Erro ao tentar cancelar',
                        })
                    })
                    .finally(()=>{
                        getEvents()
                    })
                })
                .catch(() => {
                    
                })
            }


            const select = (hora, data, service='open')=>{
                if(hora && data){
                    if(!selecteds.value.filter(it=>it.date==data).length){
                        selecteds.value.push(
                            {
                                "date":data, 
                                "hours":[]
                            }
                        )

                        selecteds.value.map(it=>{
                            if(it.date==data){
                                if(!it.hours.filter(it2=>it2.hour==hora).length){
                                    it.hours = [{"hour":hora, "action":"add"}]
                                }
                            }
                        })
                    }else{
                        selecteds.value.map(it=>{
                            if(it.date==data){
                                
                                if(it.hours.filter(it2=>it2.hour==hora).length){
                                    console.log('cai aqui')
                                    it.hours.map(it2=>{
                                        if(it2.hour==hora){
                                            it2.action =  it2.action=='add'? 'del' : 'add'
                                        }
                                    })
                                }else{
                                    it.hours.push({hour:hora, action:'add'})
                                }
                                
                            }
                        }) 
                    }
                    console.log(hora,data)

                    key.value++;
                }
            }

            const recalc = () =>{
                days.value = ['']
                hours.value = ['']
                for (let i = 0; value.value[1] - value.value[0] >= i; i++) {
                    hours.value.push(Moment(value.value[0],'HH').add({hours:+i}).format('HH:mm:ss'))
                }

                for (let i = 0; i <= 6; i++) {
                    days.value.push(Moment(dayInitial.value).add({days:+i}).format('YYYY-MM-DD'))
                }
            }

            const getBooks = ()=>{
                BookService.getAllAdmin(Moment(dayInitial.value).format('YYYY-MM-DD'), Moment(dayInitial.value).add({days:+6}).format('YYYY-MM-DD'))
                .then(r=>{
                    listBooks.value = r.data
                }).finally(()=>{
                    load.value = false
                })
            }

            const handleClose = () => {
                getEvents()
            }

            const getEvents = () =>{
                load.value = true
                selecteds.value = []
                EventService.getAllAdmin(Moment(dayInitial.value).format('YYYY-MM-DD'), Moment(dayInitial.value).add({days:+6}).format('YYYY-MM-DD'))
                .then(r=>{
                    eventList.value = r.data;
                    r.data.map(item=>{
                        if(selecteds.value.filter(it=>it.date==Moment(item.start_time).format('YYYY-MM-DD')).length){
                            selecteds.value.map(it=>{
                                if(it.date==Moment(item.start_time).format('YYYY-MM-DD')){
                                    if(!it.hours.filter(it2=>it2.hour==Moment(item.start_time).format('HH:mm:ss')).length){
                                        it.hours.push(
                                            {
                                                hour:Moment(item.start_time).format('HH:mm:ss'), 
                                                action:'add'
                                            }
                                        )
                                    }
                                }
                            })
                        }else{
                            selecteds.value.push(
                                {
                                    "date": Moment(item.start_time).format('YYYY-MM-DD'),
                                    "hours": [{
                                        hour:Moment(item.start_time).format('HH:mm:ss'), 
                                        action:'add'
                                    }]
                                }
                            )
                        }
                    })
                    getBooks()
                })
                .finally(()=>{
                    recalc()
                })
            }

            watch(() => value.value, (first, second) => {
                console.log(
                    "Watch value function called with args:",
                    first,
                    second
                );
                localStorage.setItem('rangeHour', JSON.stringify(value.value))
                recalc()
            });

            onMounted(()=>{
                console.log('mounted')
                getEvents()
            })

            return{
                days,
                hours,
                moment,
                select,
                dayInitial,
                recalc,
                key,
                selecteds,
                create,
                getEvents,
                load,
                dialogVisibleReserva,
                handleClose,
                daySelected,
                hourSelected,
                openConfirmDelete,
                createBook,

                listBooks,
                value,

                mobile_phone,
                date_of_birthday,
                name_user
            }
        }
    }
</script>