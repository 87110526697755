import http from "../http-common";

class AuthService {
  login(data) {
    return http.post("/auth/login", data);
  }

  register(data) {
    return http.post("/auth/register", data);
  }

  current(data) {
    console.log(data)
    return http.get("/auth/user-profile", {
        headers:{
            'Authorization':'Bearer '+data.access_token
        }
    });
  }


}

export default new AuthService();