<template>
  <div class="px-2">

    <div v-if="!data.filter(it=>it.start_time>=moment()().format('YYYY-MM-DD HH:mm:ss')).length && load==false" class="py-5">
      <p class="text-center">Não encontramos sessões para este dia 😕</p>
    </div>
    
    <el-card v-for="item in data.filter(it=>it.start_time>=moment()().format('YYYY-MM-DD HH:mm:ss'))" :key="item" class="box-card my-2">
        <div class="w-full flex items-center rounded  gap-5">
            <div class="w-3/12 flex items-center gap-1 text-2xl text-primary font-bold">
              <svg style="width:30px;" class="text-primary" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>

              {{moment()(item.start_time).format('HH')}}h
            </div>
            <div class="h-10 w-1 bg-gray-300"></div>
            <div class="flex flex-col w-full">
                <span class="font-bold">{{item.name}}</span>
                <span class="text-sm">{{item.name}}</span>
                <span class="text-xs ">
                  <span class="capitalize">
                    {{moment()(item.start_time).format('dddd')}} - 
                  </span>
                  {{moment()(item.start_time).format('DD')}} de 
                  {{moment()(item.start_time).format('MMMM')}} de 
                  {{moment()(item.start_time).format('YYYY')}}
                </span>
                <div class="flex justify-end py-2">
                    <button 
                          id="book-start"
                          v-if="books!=undefined"
                          :id="'agendar-'+moment()(item.start_time).format('HH')" 
                          @click="agendar(item)" 
                          :disabled="books.filter(it=>it.id_event==item.id).length>0"
                          class="px-4 py-2 text-red-600 rounded hover:opacity-90" 
                          :class="books.filter(it=>it.id_event==item.id).length? bookStore.myBooksId.filter(itMy=>itMy==books.filter(it=>it.id_event==item.id)[0].id).length ? 'text-green-600 font-bold' : 'text-red-600 font-bold':' bg-primary text-white'">
                      {{ 
                        books.filter(it=>it.id_event==item.id).length?
                          bookStore.myBooksId.filter(itMy=>itMy==books.filter(it=>it.id_event==item.id)[0].id).length ? 
                            'Reservado para mim' :
                          'Lotado' : 
                        'Agendar' 
                      }}
                    </button>
                </div>
            </div>
        </div>
    </el-card>
    
  </div>  

  <el-dialog
    v-model="dialogVisibleLogin"
    :title="form.action==true?'Crie sua conta':'Entrar na sua conta'"
    width="95%"
    @closed="handleClose()"
  >
    <div class="flex flex-col gap-3">
      <div class="flex justify-center">
       <!-- <el-switch
          v-model="form.action"
          class="mb-2"
          active-text="Não tenho conta"
          style="--el-switch-on-color: var(--el-color-primary); --el-switch-off-color: var(--el-color-primary);"
          inactive-text="Já tenho conta"
        />-->
      </div>

      <form @submit.prevent="form.action==true?register():login()">
        <div class="" v-if="form.action==true" >
          <span>Seu nome</span>
          <el-input required v-model="form.name" placeholder="Nome" />
        </div>
        <div class="">
          <span>Email</span>
          <el-input required type="email" v-model="form.email" placeholder="email" />
        </div>
        <div>
          <span>Senha</span>
          <el-input required minlength="6" v-model="form.password" type="password" placeholder="Senha" />
        </div>

        <div class="flex justify-end py-6">
          <button v-loading="load" v-if="form.action==true" type="submit" class="bg-primary px-4 py-2 text-white rounded">
            Criar conta nova
          </button>
          <button v-loading="load" v-else type="submit" class="bg-primary px-4 py-2 text-white rounded" >
            Entrar
          </button>
        </div>
      </form>

      <div v-if="messageErrorLogin" class="text-red-500 text-center">
        {{messageErrorLogin}}
      </div>
      
    </div>
  </el-dialog>  

  <el-dialog
    v-model="dialogVisibleReserva"
    title="Confirmação"
    width="95%"
    @closed="handleClose()"
  >
    <!-- {{ keyPaymentCall }} -->
    <span v-if="messageError != 'voce esta sem creditos'">Deseja mesmo fazer esta reserva para o dia <b>{{moment()(daySelected).format('DD/MM/YYYY')}}</b> às <b>{{moment()(hourSelected, 'HH').format('HH:mm')}}</b> ? Preencha seus dados abaixo para reservar esse horário.</span>


    <div class="py-5">
      <form action="" class="flex flex-col gap-5">
        
        <div>
          <label for="true">Nome completo</label>
          <input placeholder="Seu nome" v-model="name_user" type="text" class="border w-full px-2 py-3 text-md"/>
        </div>

        <div>
          <label for="true">Telefone</label>
          <input placeholder="(99) 99999-9999" v-mask="'(00) 00000-0000'" type="text" v-model="mobile_phone" class="border w-full px-2 py-3 text-md"/>
        </div>

        <div>
          <label for="true">Data de Nascimento</label>
          <input v-model="date_of_birthday" placeholder="DD/MM/AAAA" v-mask="'00/00/0000'"  type="text" class="border w-full px-2 py-3 text-md"/>
        </div>
        
      </form>
    </div>
    


    <template #footer >
      <span v-if="messageError != 'voce esta sem creditos'" class="dialog-footer flex items-center justify-end gap-2" v-loading="load">
        <el-button @click="dialogVisibleReserva = false">Cancelar</el-button>
        <button 
              @click="book()"
              :disabled="!(name_user && mobile_phone && mobile_phone.length==15 && date_of_birthday && date_of_birthday.length==10)"
              class="bg-primary px-4 py-1 text-white rounded hover:opacity-90" 
              :class="!(name_user && mobile_phone && mobile_phone.length==15 && date_of_birthday && date_of_birthday.length==10) && 'opacity-50 hover:opacity-50'"
              >
              Confirmar
        </button>
      </span>
    </template>
  </el-dialog>

</template>

<script>
import {ref, onMounted} from 'vue'
import { useAuthStore } from '../stores/auth'
import { useBookStore } from '../stores/book'
import Moment from 'moment'
import AuthService from "../services/auth";
import BookService from "../services/book";
import OrderService from "../services/order";
import PaymentService from "../services/payment";
import { ElNotification } from 'element-plus'
import { ElMessage, ElMessageBox } from 'element-plus'
import Globals from '../../env'


Moment.locale('pt-br');


export default {

    props:['data', 'books', 'daySelected'],
    setup(props, {emit}){
        
        const dialogVisibleLogin = ref(false)

        const dialogVisibleReserva = ref(false)

        const authStore = useAuthStore()

        const bookStore = useBookStore()

        const hourSelected = ref('')

        const eventSelected = ref('')

        const messageSuccess = ref('')

        const order = ref('')

        const paymentData = ref({payment_internal:{status:'pending'}})

        const keyPaymentCall = ref(0)

        const mobile_phone = ref('')
        const date_of_birthday = ref('')
        const name_user = ref('')

        const moment = ()=>(Moment)

        onMounted(()=>{

          name_user.value = localStorage.getItem('name_user') || ''
          date_of_birthday.value = localStorage.getItem('date_of_birthday') || ''
          mobile_phone.value = localStorage.getItem('mobile_phone') || ''

          let query = window.location.search

          const paramsQuery = Object.fromEntries(  
            new URLSearchParams(query)
          )

          const params = JSON.parse(JSON.stringify(paramsQuery))

          if(params.hourClick){
            let interval = setInterval(()=>{
              if(document.querySelector('#agendar-'+params.hourClick)){
                document.querySelector('#agendar-'+params.hourClick).click()
                clearInterval(interval)
              }
            },500)
          }

          if(params.login){
            dialogVisibleLogin.value = true
          }

          
        })


        const agendar = (data)=>{

          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
              event: 'click_book',
              event_type: 'kpi',
              journey: 'click_book',
              section: 'button',
              title: 'click_book',
              element_data_id: 'book-start'
          })
          
          eventSelected.value = data
          //if(authStore.logged){
            dialogVisibleReserva.value=!dialogVisibleReserva.value
          //}else{
            //dialogVisibleLogin.value=!dialogVisibleLogin.value
          //}

          hourSelected.value = Moment(data.start_time).format('HH')
          
        }

        const getDataPayment = (id)=>{
            PaymentService.get(id, authStore.token)
            .then(r=>{
              paymentData.value = r.data
              keyPaymentCall.value ++
              if(r.data.payment_internal.status=='pending'){
                getDataPayment(id)
              }else{
                BookService.create(
                  {
                    id_user:authStore.user.id,
                    id_event: eventSelected.value.id
                  },
                  authStore.token
                ).then(r=>{
                  ElNotification({
                        title: 'Sucesso',
                        message: 'reserva feita com sucesso!',
                        type: 'success',
                  })
                })
              }
            })
        }

        function handleClose(){
          emit('updateData')
        }

        function cancelar(id, item){
          ElMessageBox.confirm(
            `Deseja mesmo cancelar esta reserva para o dia ${moment()(item.start_time).format('DD/MM/YYYY HH:mm:ss')}?`,
            'Cancelamento de reserva',
            {
              confirmButtonText: 'Confirmar',
              cancelButtonText: 'Fechar',
              type: 'warning',
            }
          )
            .then(() => {

              BookService.cancel(
                  id,
                  authStore.token
                ).then(r=>{
                  ElNotification({
                        title: 'Sucesso',
                        message: 'reserva cancelada com sucesso!',
                        type: 'success',
                  })
              })
              .catch(e=>{
                ElNotification({
                        title: 'Erro',
                        message: e.response.data,
                        type: 'error',
                })
              }).finally(()=>{
                emit('updateData')
              })

              
            })

        }

        const book = async ()=>{

          load.value = true
          messageError.value = ''
          try{
            localStorage.setItem('name_user', name_user.value)
            localStorage.setItem('date_of_birthday', date_of_birthday.value)
            localStorage.setItem('mobile_phone', mobile_phone.value)

            let result = await  BookService.create(
              {
                id_user:authStore.user.id,
                id_event: eventSelected.value.id,
                name_user:name_user.value,
                date_of_birthday:date_of_birthday.value.split('/').reverse().join('-'),
                mobile_phone:mobile_phone.value
              },
              authStore.token
            )

            bookStore.addBookId(result.data.id)
            messageSuccess.value = 'Reserva Realizada'
            dialogVisibleReserva.value = false
            emit('updateData')
            ElNotification({
              title: 'Sucesso',
              message: messageSuccess.value,
              type: 'success',
            })

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'book_success',
                event_type: 'kpi',
                journey: 'reserva',
                section: 'modal',
                title: 'reserva-sucesso',
                element_data_id: 'book-start'
            })

          } catch(e){
            dialogVisibleReserva.value=!dialogVisibleReserva.value
            ElNotification({
              title: 'Erro',
              message: e.response.data.message,
              type: 'error',
            })

            emit('updateData')
            
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'book_error',
                event_type: 'kpi',
                error_message: e.response.data.message || 'Erro nao catalogado',
                journey: 'reserva',
                section: 'modal',
                title: 'reserva-error',
                element_data_id: 'book-start'
            })
            
            if(e.response.data.message=="voce esta sem creditos"){

                OrderService.create(
                  {
                      "id_user":authStore.user.id,
                      "id_package":1,
                      "method":"mp_pix"
                  },
                  authStore.token 
                ).then(r=>{
                  order.value = r.data

                  getDataPayment(r.data.original.id, authStore.token)
                 // book()
                }).catch(e2=>{
                  ElNotification({
                    title: 'Erro',
                    message: e2.response.data.message,
                    type: 'error',
                  })
                })
                .finally(()=>{
                  load.value = false
                })

                
            }

            if(e.response && e.response.status==401){
              messageError.value = 'Erro de autorizacao'
            }else{
              messageError.value = 'Erro no servidor'
            }

            if(e.response.data.message=='voce esta sem creditos'){
              messageError.value = e.response.data.message
            }
            
          }

          load.value = false

        }


        async function current(data) {
          try{
            let result = await  AuthService.current(data)
            result = result.data
            authStore.setUserLogged(result)

            //admin login
            let query = window.location.search

            const paramsQuery = Object.fromEntries(  
              new URLSearchParams(query)
            )

            const params = JSON.parse(JSON.stringify(paramsQuery))

            if(params.login && result.level == 1){
              window.location.href = '/admin'
              return
            }

            //admin login

            dialogVisibleLogin.value = false
            window.location.href="/?hourClick="+hourSelected.value

          } catch(e){
            if(e.response && e.response.status==401){
              messageErrorLogin.value = 'Erro ao recuperar dados'
            }else{
              messageErrorLogin.value = 'Erro no servidor'
            }
            
          } 
        }


        async function login() {
          load.value = true
          messageErrorLogin.value = ''
          try{
            let result = await  AuthService.login({email:form.value.email+'.'+Globals.VITE_TENANT,password:form.value.password, tenant:Globals.VITE_TENANT})
            result = result.data
            authStore.logginSuccess(result)
            let result2 = await current(result)

          } catch(e){
            if(e.response && e.response.status==401){
              messageErrorLogin.value = 'Email ou senha inválidos'
            }else{
              messageErrorLogin.value = 'Erro no servidor'
            }
            
          }

          
          load.value = false
         
        }

        async function register() {
          load.value = true
          messageErrorLogin.value = ''
          try{
            let result = await  AuthService.register({email:form.value.email+'.'+Globals.VITE_TENANT,password:form.value.password, name:form.value.name, tenant:Globals.VITE_TENANT})
            result = result.data
            authStore.logginSuccess(result)
            let result2 = await current(result)
          } catch(e){
            if(e.response && e.response.status==401){
              messageErrorLogin.value = 'Email ou senha inválidos'
            }else{
              messageErrorLogin.value = 'Erro no servidor'
            }
            
          }

          
          load.value = false
         
        }


        const form = ref({
          name:'',
          email:'',
          password:'',
          action:false
        })

        const load = ref(false)

        const messageErrorLogin = ref('')

        const messageError = ref('')

        return{
            dialogVisibleLogin,
            dialogVisibleReserva,
            authStore,
            form,
            login,
            messageErrorLogin,
            load,
            agendar,
            register,
            moment,
            hourSelected,
            messageError,
            book,
            order,
            paymentData,
            keyPaymentCall,
            handleClose,
            cancelar,

            mobile_phone,
            date_of_birthday,
            name_user,
            bookStore
            
        }
    }
}
</script>

<style>

</style>