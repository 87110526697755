<template>
    <div class="navbar bg-base-500 shadow-lg fixed bg-white" :key="updateKey" style="z-index:999;">
            <div class="navbar-start">
                <div class="dropdown">
                <label tabindex="0" class="btn btn-ghost btn-circle">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h7" /></svg>
                </label>
                <ul tabindex="0" class="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52">
                    <li><a @click="changeCurrentView('dashboard')">Dashboard</a></li>
                    <li><a @click="changeCurrentView('sessoes')">Sessões</a></li>
                    <li><a @click="changeCurrentView('reservas')">Reservas</a></li>
                    <li><a @click="logout()">Sair</a></li>
                </ul>
                </div>
            </div>
            <div class="navbar-center">
                <a class="btn btn-ghost normal-case text-xl">Painel Admin</a>
            </div>
            <div class="navbar-end" style="visibility:hidden;">
                <button class="btn btn-ghost btn-circle">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" /></svg>
                </button>
                <button class="btn btn-ghost btn-circle">
                <div class="indicator">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" /></svg>
                    <span class="badge badge-xs badge-primary indicator-item"></span>
                </div>
                </button>
            </div>
        </div>

        <div style="z-index:1;height: 70px;"></div>
</template>


<script>
    import { ref } from 'vue'

    export default{
        setup(props,{ emit }){

            const updateKey = ref(1)

            const changeCurrentView = (val) =>{
                setTimeout(()=>{
                    updateKey.value = updateKey.value + 1
                },50)
                emit('changeCurrentView', val);
            }

            function logout(){
                localStorage.removeItem('auth')
                setTimeout(()=>{
                    window.location.href="/"
                },500)
            }

            return{
                changeCurrentView,
                updateKey,
                logout
            }
        }
    }
</script>