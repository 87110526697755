<template>

    <h3 class="text-2xl py-5 text-center">Reservas</h3>

    <div v-loading="load">
        <div class="flex justify-center flex-col items-center">
            <!-- <span class="text-xs">Data inicio</span> -->
            <el-date-picker
                v-model="dayInitial"
                type="date"
                format="DD/MM/YYYY"
                value-format="YYYY-MM-DD"
                placeholder="Data de inicio"
                class="w-full"
                :size="'large'"
                @change="getBooks()"
            />
        </div>



        <div v-for="(item, index) in listBooks" :key="index" class="py-6">
            <h4 class="text-xl ">
                <span class="capitalize">{{moment()(item.date, 'DD/MM/YYYY').format('ddd')}}</span>,
                {{moment()(item.date, 'DD/MM/YYYY').format('DD')}} de 
                {{moment()(item.date, 'DD/MM/YYYY').format('MMMM')}} de 
                {{moment()(item.date, 'DD/MM/YYYY').format('YYYY')}}
            </h4>

            <div v-for="(item2, index2) in item.books" :key="index2" class="shadow border rounded my-3 text-xs">
                <div class="px-3 py-1 my-2 flex justify-between items-center">
                    <div class="font-bold text-xl">{{moment()(item2.start_time_event).format('HH')+'h'}}</div>
                    <div class="font-bold" :class="item2.confirm==0?'text-red-400':'text-green-400'">{{item2.confirm==0?'Não confirmado':'Confirmado'}}</div>
                </div>
                <div class="px-3 flex justify-between">
                    <div>
                        <div class="font-bold">Sessão especial</div>
                        <div class="">{{moment()(item2.start_time_event).format('DD/MM/YYYY')}}</div>
                    </div>

                    <div @click="bookSelected=item2;dialogVisible=true;valueSwitch=item2.confirm==0?false:true;" class="flex flex-col justify-center items-end">
                        <div class="text-xs">Reservado em</div>
                        <div class="text-xs">{{moment()(item2.created_at).format('DD/MM/YYYY HH:mm:ss')}}</div>
                        <div
                            class="text-white px-2 rounded" 
                            :class="{
                            'bg-primary':item2.id_user_created==0,
                            'bg-blue-400':item2.id_user_created!=0,
                        }">{{ item2.id_user_created==0 ? 'Via App' : 'Via Adm'}}</div>
                    </div>
                </div>

                <div class="px-3 flex justify-between py-3">
                    <a 
                        :href="`https://api.whatsapp.com/send?phone=55${item2.mobile_phone.replace('(', '').replace(')', '').replace(' ', '').replace('-', '')}`" 
                        target="_blank">
                        <div class="font-bold">{{item2.name_user}}</div>
                        <div class="">{{item2.mobile_phone}}</div>
                        <div class="">{{moment()(item2.date_of_birthday).format('DD/MM/YYYY')}}</div>
                    </a>

                    <div v-if="item2.value_paid" class="flex flex-col justify-center items-end">
                        <div class="text-xs">Pago</div>
                        <div class="text-xs font-bold text-green-500">{{item2.value_paid.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}</div>
                    </div>
                </div>

            </div>

        </div>

    </div>

    <el-dialog
        v-model="dialogVisible"
        title="Opções da reserva"
        width="350px"
        @close="handleClose"
    >
        <div v-if="bookSelected && bookSelected.name_user">
            Reserva de <b>{{bookSelected.name_user}}</b><br>
            Data: <b>{{moment()(bookSelected.start_time_event).format('DD/MM/YYYY HH:mm')}}</b><br>
            <div class="py-6">
                <el-switch
                    
                    v-model="valueSwitch"
                    size="large"
                    active-text="Confirmado"
                    inactive-text="Não confirmado"
                />
            </div>

            <div class="py-1 flex flex-col gap-1">
                <label for="" style="margin-bottom: 4px;">Valor Pago</label>
                <input v-model.lazy="valuePaid" v-money3="config" class="w-full px-2 py-2 rounded border focus:outline-none focus:border-purple-800" />
            </div>

            <div class="py-3 flex flex-col gap-1">
                <el-button type="danger" plain @click="cancel()">Cancelar reserva</el-button>
            </div>
        </div>
        <template #footer>
        <span class="dialog-footer">
            
            <el-button type="primary" @click="editBook()">
            Confirmar
            </el-button>
        </span>
        </template>
    </el-dialog>

    

</template>

<script>
    import { ref, onMounted } from 'vue'
    import BookService from "../../../services/book";
    import Moment from 'moment'
    import { Money3Directive } from 'v-money3'
    import { useAuthStore } from '../../../stores/auth'
    import { ElNotification } from 'element-plus'
    import { ElMessage, ElMessageBox } from 'element-plus'
    import _ from 'lodash'
    
    export default{
        props:['name'],
        directives: { money3: Money3Directive },

        setup(){
            const authStore = useAuthStore()
            const value1 = ref('')
            const dayInitial = ref(Moment().format('YYYY-MM-DD'))
            const load = ref(false)
            const listBooks = ref([])
            const dialogVisible = ref(false)
            const bookSelected = ref({})
            const valueSwitch = ref(false)
            const valuePaid = ref('')

            const config = {
                prefix: 'R$ ',
                suffix: '',
                thousands: '.',
                decimal: ',',
                precision: 2,
                disableNegative: false,
                disabled: false,
                min: null,
                max: null,
                allowBlank: false,
                minimumNumberOfCharacters: 0,
                shouldRound: true,
                focusOnRight: false,
            }

            const moment = () =>{
                return Moment
            }

            const handleClose = ()=>{
                getBooks()
            }

            const getBooks = ()=>{
                load.value = true
                BookService.getAllAdmin(Moment(dayInitial.value).format('YYYY-MM-DD'), Moment(dayInitial.value).add({days:+1000}).format('YYYY-MM-DD'))
                .then(r=>{
                    let result = []//[{date:'18 de Abril de 2023', books:[]}]
                    _.orderBy(r.data, ['start_time_event', 'created_at'], ['asc', 'asc']).map(it=>{
                        if(result.length==0){
                            result.push({date:Moment(it.start_time_event).format('DD/MM/YYYY'), books:[]})
                        }else{
                            if(!result.filter(it2=>it2.date==Moment(it.start_time_event).format('DD/MM/YYYY')).length){
                                result.push({date:Moment(it.start_time_event).format('DD/MM/YYYY'), books:[]})
                            }
                        }
                    })

                    _.orderBy(r.data, ['start_time_event', 'created_at'], ['asc', 'asc']).map(it=>{
                        result.map(it2=>{
                            if(Moment(it.start_time_event).format('DD/MM/YYYY')==it2.date){
                                it2.books.push(it)
                            }
                        })
                    })

                    listBooks.value = result
                }).finally(()=>{
                    load.value = false
                })
            }

            const converterFormatoBRLparaUSD = (valorBRL) => {
                var valor = Number(valorBRL.replace(/\./g, '').replace(',', '.'));

                // Converter para formato de moeda com localidade dos Estados Unidos (en-US)
                var valorUSD = valor.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
                
                valorUSD = valorUSD.replace('$', '');
                valorUSD = valorUSD.replace(',', '');

                return valorUSD;
            }

            const cancel = (id=bookSelected.value.id) => {
                ElMessageBox.confirm(
                    'Deseja mesmo cancelar esta reserva?',
                    'Cancelar reserva',
                    {
                    confirmButtonText: 'Confirmar',
                    cancelButtonText: 'Cancelar',
                    type: 'warning',
                    }
                )
                .then(() => {
                    BookService.cancel(id, authStore.token)
                    .then(r=>{
                        ElMessage({
                            type: 'success',
                            message: 'Cancelada com sucesso!',
                        })

                        dialogVisible.value = false
                    })
                    .catch(()=>{
                        ElMessage({
                            type: 'error',
                            message: 'Erro ao tentar cancelar',
                        })
                    })
                    .finally(()=>{
                        getBooks()
                    })
                })
                .catch(() => {
                    
                })
            }



            const editBook = ()=>{
                load.value = true
                BookService.edit(bookSelected.value.id, {
                    value_paid: converterFormatoBRLparaUSD(valuePaid.value.replace('R$ ', '')),
                    confirm: valueSwitch.value?1:0
                }, authStore.token)
                .then(r=>{
                    dialogVisible.value=false
                })
                .catch(e=>{
                    console.log(e.response)
                })
            }

            onMounted(()=>{
                getBooks()

                console.log(Moment().endOf('week').format('DD/MM/YYYY'))
            })

            return{
                value1,
                listBooks,
                moment,
                dialogVisible,
                bookSelected,
                valueSwitch,
                valuePaid,
                config,
                handleClose,
                load,
                editBook,
                cancel,
                dayInitial,
                getBooks
            }
        }
    }
</script>