import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/tailwind.css'
import money from 'v-money3'

import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

import VueMask from '@devindex/vue-mask'; // <-- ADD THIS LINE

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import './styles/index.scss'

import './style.css'


const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

createApp(App)
    .use(pinia)
    .use(ElementPlus)
    .use(money)
    .use(VueMask)
    .use(router).mount('#app')
