import http from "../http-common";

class PaymentService {


  get(data, token) {
    return http.get("/payment/"+data, {
      headers:{
          'Authorization':'Bearer '+token,
      }
    });
  }


}

export default new PaymentService();