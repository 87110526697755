

const globals = {
    VITE_TENANT:'migweb',
    //VITE_BASE_URL_API:'https://api.migdigital.space/api'
    VITE_BASE_URL_API:'https://api.alexandrearaujofisio.com.br/api'
    //VITE_BASE_URL_API:'http://localhost:8000/api'
}

export default globals;
