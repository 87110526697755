<template>
    <div v-if="authStore.user.level==1">

        <Header
            @changeCurrentView="currentView=$event;"
        />

        <div style="z-index:1;" class="px-3">

            <Dashboard
                v-if="currentView=='dashboard'"
                :name="authStore.user.name"
            />

            <Sessoes
                v-if="currentView=='sessoes'"
            />

            <Reservas
                v-if="currentView=='reservas'"
            />

            <Whatsapp 
                v-if="currentView=='whatsapp'">

            </Whatsapp>
            
            
        </div>


    </div>
</template>

<script >
    import { useDark, useToggle } from '@vueuse/core'
    import { useAuthStore } from '../../stores/auth'
    import {onBeforeMount, ref, onMounted} from 'vue'
    import Header from './components/Header.vue'
    import Dashboard from './components/Dashboard.vue'
    import Sessoes from './components/Sessoes.vue'
    import Reservas from './components/Reservas.vue'
    import Whatsapp from './components/Whatsapp.vue'

    const isDark = useDark()
    const toggleDark = useToggle(isDark) 


    export default{

        title: 'Homepage Title',

        components:{
            Header,
            Dashboard,
            Sessoes,
            Reservas,
            Whatsapp
        },
        setup() {

            const authStore = useAuthStore()

            const currentView = ref('dashboard')

            
            

            onBeforeMount(() => {

                

                if(authStore.user.level!=1){
                    window.location.href = '/?login=true'
                }
            })

            

                // expose to template and other options API hooks
            return {
                authStore,
                currentView
            }
        }


    }

    

</script>

<style>

</style>