<template>

    <div class="w-full bg-white shadow-lg rounded p-2">
        <p v-if="false" class="text-center text-red-400 font-bold py-5">
            Whatsapp Desconectado
            <br>
            <button class="px-5 py-1 text-white font-normal btn-primary rounded mt-5">Conectar</button>
        </p>
        <p v-else class="text-center text-green-400 font-bold py-5">
            Whatsapp Conectado <br>
            <span class="text-black font-normal">Sessao: <b>Testes</b></span>
            <br>
            <button class="px-5 py-1 text-white font-normal bg-red-400 hover:bg-red-500 rounded mt-5">Desconectar</button>
        </p>
    </div>

</template>

<script>
    import { ref, onMounted } from 'vue'
    import { useAuthStore } from '../../../stores/auth'
    import Moment from 'moment'

    export default{

        setup(){

            const data = ref([])
            

            onMounted(()=>{
                
            })

            return {
                data
            }
        }
    }
</script>