<template>
  <div :style="scrollTop>=300?'position:fixed;top:0;':''" class="py-2 w-full bg-primary text-white flex justify-between overflow-auto " id="bar-days">
    <div 
        v-for="(item, index) in daysList" 
        :key="index" 
        style="min-width:80px;" 
        :class="item==daySelected?'bg-primary-select':''" 
        class="flex flex-col items-center p-2 rounded box-day"
        @click="daySelected = item;"
    >
        <!-- <span>Mar</span> -->
        <span class="font-bold">{{moment()(item).format('DD')}}/{{moment()(item).format('MM')}}</span>
        <span class="capitalize">{{moment()(item).format('ddd')}}</span>
        <!-- <span class="capitalize text-xs" :style="moment()(item).format('DD')=='01'?'visibility:visible;':''">{{moment()(item).format('MMM')}}</span> -->
    </div>
  </div>   
  
  <div :style="scrollTop>=290?'height:30px;margin-bottom:10px;':'margin-bottom:10px;'"></div>

</template>

<script>
import { computed, ref, watch, onMounted } from 'vue';
import Moment from 'moment'

export default {
    
    setup(props, { emit }){
        const scrollTop = ref(document.documentElement.scrollTop)

        const daysList = ref([])

        const daySelected = ref( localStorage.getItem('daySelected') ? localStorage.getItem('daySelected') < Moment().format('YYYY-MM-DD') ? Moment().format('YYYY-MM-DD') : localStorage.getItem('daySelected') : Moment().format('YYYY-MM-DD'));

        const moment = ()=>(Moment)

        watch(daySelected, (val) => {
            emit('dayChange', val);
            localStorage.setItem('daySelected', val)
        });

        onMounted(()=>{


          document.querySelector('#bar-days').addEventListener('scroll', (e)=>{
            console.log(document.querySelector('#bar-days').scrollLeft)
            localStorage.setItem('scroll-bar-days', document.querySelector('#bar-days').scrollLeft)
          })

          if(localStorage.getItem('scroll-bar-days')){
            if(localStorage.getItem('daySelected')){
              if(localStorage.getItem('daySelected') < Moment().format('YYYY-MM-DD')){
                document.querySelector('#bar-days').scrollLeft = 0
              }else{
                document.querySelector('#bar-days').scrollLeft = localStorage.getItem('scroll-bar-days')
              }
            }
            
          }
        })

        const returnNameWeekPorBr = (nameEng)=>{
          if(nameEng=='Tue') return 'Ter'
          if(nameEng=='Wed') return 'Qua'
          if(nameEng=='Thu') return 'Qui'
          if(nameEng=='Fri') return 'Sex'
          if(nameEng=='Sat') return 'Sáb'
          if(nameEng=='Sun') return 'Dom'
          if(nameEng=='Mon') return 'Seg'
        }

        let currentDay = Moment().format('YYYY-MM-DD');

        let i = 0

        while(i<=30){
          daysList.value.push(Moment(currentDay).add({days:+i}).format('YYYY-MM-DD'))
          i++
        }

        window.addEventListener("scroll", (event) => {
            scrollTop.value = document.documentElement.scrollTop;
        });

        return {scrollTop, daysList, moment, returnNameWeekPorBr, daySelected}
    }
}
</script>

<style>
  .box-day{
    cursor:pointer;
  }
  .bg-primary{
    background-color: var(--el-color-primary);
  }

  .text-primary{
    color: var(--el-color-primary);
  }

  .bg-primary-select{
    background-color: var(--el-color-primary);
    filter: brightness(85%);
  }
</style>