<script>
  import Head from '../../components/Head.vue'
  import DaySelector from '../../components/DaySelector.vue'
  import ListSession from '../../components/ListSession.vue'
  import { ref, onMounted } from 'vue';
  import EventService from "../../services/event";
  import BookService from "../../services/book";
  import { Loading } from 'element-plus/es/components/loading/src/service';
  import 'moment/dist/locale/pt-br'
  import Moment from 'moment';
  

  
  Moment.locale('pt-br');

  export default{
    components:{Head, DaySelector, ListSession},
    setup(){
      const count = ref('')

      const listEvents = ref([])

      const listBooks = ref(undefined)

      const daySelected = ref('')
      

      const messageError = ref('')

      const load = ref(false)

      const changeDay = (data)=>{
        getEvents(data)
        daySelected.value = data
      }

      onMounted(()=>{
        getEvents(localStorage.getItem('daySelected') || Moment().format('YYYY-MM-DD'))
        daySelected.value = Moment().format('YYYY-MM-DD')

        let head = document.querySelector('head')

        let script1 = document.createElement('script')
        script1.src = 'https://www.googletagmanager.com/gtag/js?id=G-GR5BNBYX5F'

        let script2 = document.createElement('script')
        script2.innerHTML = `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-GR5BNBYX5F');
        `

        head.appendChild(script1)
        head.appendChild(script2)


      })

      async function getEvents(day=localStorage.getItem('daySelected')) {
          load.value = true
          messageError.value = ''
          try{
            let result = await EventService.getAll(day)
            listEvents.value = result.data

            getBooks(day)

          } catch(e){
            if(e.response && e.response.status==401){
              messageError.value = 'Erro 401'
            }else{
              messageError.value = 'Erro no servidor'
            }
            
          }

          
          //load.value = false
         
      }

      async function getBooks(day) {
          //load.value = true
          messageError.value = ''
          try{
            let result = await BookService.getAll(day)
            listBooks.value = result.data
          } catch(e){
            if(e.response && e.response.status==401){
              messageError.value = 'Erro 401'
            }else{
              messageError.value = 'Erro no servidor'
            }
            
          }

          
          load.value = false
         
      }

      async function test() {
          console.log('update')
         
      }

      return {
        count,
        changeDay,
        load,
        listEvents,
        listBooks,
        daySelected,
        getEvents,
        test
      }
    }

  }


</script>

<template>
  
  <Head/>

  <div v-loading="load">

    <DaySelector @dayChange="changeDay($event)"/>

  
    <ListSession @updateData="getEvents" :data="listEvents" :books="listBooks" :load="load" :daySelected="daySelected"/>
  </div>

  

</template>

<style scoped>

</style>