import { defineStore } from 'pinia'

export const useBookStore = defineStore({
  persist: true,
  id: 'book',
  state: () => ({
    myBooksId: [],
  }),
  getters: {
    
  },
  actions: {
    addBookId(data) {
      this.myBooksId.push(data)
    }
    // decreaseFontSize(amount) {
    //   this.fontSize -= amount
    // },
    // resetFontSize() {
    //   this.fontSize = 16
    // }
  }
})