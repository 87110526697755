import { createRouter, createWebHistory } from 'vue-router'
import HomePublic from '../views/public/Home.vue'
import HomeAdmin from '../views/admin/Home.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePublic,
    meta: { title: 'Skills - MyApp' } // <- I would to use this one
  },
  {
    path: '/admin',
    name: 'admin',
    component: HomeAdmin,
    meta: { title: 'Skills - MyApp' } // <- I would to use this one
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
