import http from "../http-common";

class EventService {
  login(data) {
    return http.post("/auth/login", data);
  }

  register(data) {
    return http.post("/auth/register", data);
  }

  create(data, token) {
    return http.post("/events/events", data, {
      headers:{
          'Authorization':'Bearer '+token,
      }
    });
  }

  getAll(data) {
    console.log(data)
    return http.get(`/events?date_from=${data}&date_to=${data}`, {
        headers:{
            'Authorization':'Bearer '+data.token,
        }
    });
  }

  getAllAdmin(date_from, date_to) {
    return http.get(`/events?date_from=${date_from}&date_to=${date_to}`);
  }


}

export default new EventService();