import axios from "axios";
import Globals from '../env';
// import { useAuthStore } from './stores/auth'

// const authStore = useAuthStore()

console.log(localStorage.getItem('auth'))

let headers = {
    "Content-type": "application/json",
    "Accept": "application/json"
}


// if(authStore.token){
//     headers['Authorization'] = 'Bearer '+authStore.token
// }



export default axios.create({
  baseURL: Globals.VITE_BASE_URL_API,
  headers: {
    ...headers
  }
});